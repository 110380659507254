<template>
    <div>
        <h2>Datenschutzerklärung</h2>
        <p>Wir freuen uns über Ihren Besuch auf unserer Internetseite und Ihrem Interesse an unserem Unternehmen.
            Der Schutz Ihrer personenbezogenen Daten ist uns ein wichtiges Anliegen. Personenbezogene Daten sind
            Einzelangaben über persönliche oder sachliche Verhältnisse einer bestimmten oder bestimmbaren
            natürlichen Person. Darunter fallen Informationen wie der bürgerliche Name, die Anschrift, die
            Telefonnummer und das Geburtsdatum.</p>
        <p>Da diese Daten besonderen Schutz genießen, werden sie bei uns nur im technisch erforderlichen Umfang
            erhoben. Nachfolgend stellen wir dar, welche Informationen wir während Ihres Besuches auf unserer
            Internetseite erfassen und wie diese genutzt werden.</p>
        <p>Wir werden Ihre personenbezogenen Daten ausschließlich zur Bearbeitung von Anfragen und ggf. zur
            Abwicklung von Bestellungen / Verträgen erheben, verarbeiten und speichern. Nur wenn Sie zuvor Ihre
            Einwilligung gesondert erteilt haben, erfolgt eine Nutzung Ihrer Daten auch für weitergehende, in der
            Einwilligung genau bestimmte Zwecke, z.B. für Informationen über Angebote per Newsletter, etc..</p>
        <hr>
        <h2>Unser Datenschutzbeauftragter</h2>
        <p>Im Sand 44 66802 Überherrn</p>
        <p>Jürgen Dincher <a href="mailto:info@ds-values.de">info@ds-values.de</a></p>
        <hr>
        <h2>Datenerhebung</h2>
        <p>Sie können unsere Webseiten grundsätzlich besuchen, ohne uns mitzuteilen, wer Sie sind.<br>
            Aus technischen Gründen verarbeiten wir automatisch Serverlogdaten, welche von Ihrem Browser übermittelt
            werden:<br>
            • Browser-Typ/-Version<br>
            • Betriebssystem<br>
            • Referrer URL (die zuvor besuchte Seite)<br>
            • IP Adresse (diese wird vor der Speicherung anonymisiert)<br>
            • Zeitpunkt Ihres Besuches<br>
            Die Serverlogdaten können keinen Personen zugeordnet werden. Sie werden nach einer statistischen
            Auswertung automatisch gelöscht. Die rechtlichen Grundlagen finden sich in der
            Datenschutz-Grundverordnung (DSGVO), im Bundesdatenschutzgesetz (BDSG) und dem Telemediengesetz (TMG).
        </p>
        <hr>
        <h2>Newsletter</h2>
        <p>Sie haben die Möglichkeit, über unsere Website unseren Newsletter zu abonnieren. Dazu verarbeiten wir auf
            Grundlage von Artikel 6 Abs. 1 der Datenschutz-Grundverordnung Ihre persönliche E-Mail-Adresse. Mit dem
            Abonnement erklären Sie sich mit dem Bezug des Newsletters, den hier beschriebenen Maßnahmen zur
            Datenverarbeitung und zum Datenschutz einverstanden. Eine Weitergabe der E-Mail-Adresse an Dritte
            erfolgt nicht. Die E-Mail-Adresse wird ausschließlich für den Versand des Newsletters genutzt.<br>
            Nach der Anmeldung für den Newsletter, senden wir Ihnen eine Bestätigungs-E-Mail mit einem Link zur
            Bestätigung der Anmeldung.<br>
            Das Abo des Newsletters können Sie jederzeit für die Zukunft widerrufen. Einen entsprechenden
            Abbestellungslink finden Sie in jedem verschickten Newsletter. Wir löschen anschließend umgehend die
            E-Mail-Adresse Daten im Zusammenhang mit dem Newsletter-Versand.</p>
        <hr>
        <h2>Externe Inhalte und/oder Verarbeitung von Daten ausserhalb der EU</h2>
        <p>Auf unserer Internetseite verwenden wir aktive Java-Script-Inhalte von externen Anbietern. Durch Aufruf
            unserer Internetseite erhalten diese externen Anbieter ggf. personenbezogene Informationen über Ihren
            Besuch auf unserer Internetseite. Hierbei ist eine Verarbeitung von Daten außerhalb der EU möglich. Sie
            können dies verhindern, indem Sie einen Java-Script-Blocker wie z.B. das Browser-Plugin ‚NoScript‘
            installieren (www.noscript.net) oder java-Script in Ihrem Browser deaktivieren. Hierdurch kann es zu
            Funktionseinschränkungen auf Internetseiten kommen, die Sie besuchen.</p>
        <p>Wir verwenden im Einzelnen:</p>
        <p><strong>Google:</strong><br>
            Auf unserer Seite wird Java-Script Code des Unternehmens Google LLC, 1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA (nachfolgend: Google) nachgeladen. Wenn Sie in Ihrem Browser Java-Script aktiviert
            und keinen Java-Script-Blocker installiert haben, wird Ihr Browser ggf. personenbezogene Daten an Google
            übermitteln. Uns ist nicht bekannt, welche Daten Google mit den erhaltenen Daten verknüpft und zu
            welchen Zwecken Google diese Daten verwendet. Um die Ausführung von Java-Script Code von Google
            insgesamt zu verhindern, können Sie einen Java-Script-Blocker installieren (z.B. <a
                href="http://www.noscript.net">www.noscript.net</a>).</p>
        <p>Die Webseite verwendet insbesondere die Google Maps API, um geographische Informationen visuell
            darzustellen. Bei der Nutzung von Google Maps werden von Google auch Daten über die Nutzung der
            Maps-Funktionen durch Besucher der Webseiten erhoben, verarbeitet und genutzt. Nähere Informationen über
            die Datenverarbeitung durch Google können Sie den Datenschutzhinweisen von Google entnehmen. Dort können
            Sie im Datenschutzcenter auch Ihre Einstellungen verändern, so dass Sie Ihre Daten verwalten und
            schützen können.<br>
            <a href="https://support.google.com/accounts/answer/3024190" target="_blank" rel="noopener noreferrer">Hier
                finden Sie weitergehende Anleitungen zum Verwalten der eigenen Daten
                im Zusammenhang mit Google-Produkten.</a></p>
        <hr>
        <h2>Unterrichtung über die Nutzung von Cookies</h2>
        <p>Auf verschiedenen Seiten verwenden wir Cookies, um den Besuch unserer Website attraktiv zu gestalten und
            die Nutzung bestimmter Funktionen zu ermöglichen. Bei den sog. ‚Cookies‘ handelt es sich um kleine
            Textdateien, die Ihr Browser auf Ihrem Rechner ablegen kann. Der Vorgang des Ablegens einer Cookie-Datei
            wird auch ‚ein Cookie setzen‘ genannt. Sie können Ihren Browser selbst nach Ihren Wünschen so
            einstellen, dass Sie über das Setzen von Cookies informiert werden, von Fall zu Fall über die Annahme
            entscheiden oder die Annahme von Cookies grundsätzlich akzeptieren oder grundsätzlich ausschließen.
            Cookies können zu unterschiedlichen Zwecken eingesetzt werden, z.B. um zu erkennen, dass Ihr PC schon
            einmal eine Verbindung zu einem Webangebot hatte (dauerhafte Cookies) oder um zuletzt angesehene
            Angebote zu speichern (Sitzungs-Cookies). Wir setzen Cookies ein, um Ihnen einen gesteigerten
            Benutzerkomfort zu bieten. Um unsere Komfortfunktionen zu nutzen, empfehlen wir Ihnen, die Annahme von
            Cookies für unser Webangebot zu erlauben.</p>
        <hr>
        <h2>Datensicherheit und Datenschutz, Kommunikation per e-mail</h2>
        <p>Ihre personenbezogenen Daten werden durch technische und organisatorische Maßnahmen so bei der Erhebung,
            Speicherung und Verarbeitung geschützt, dass sie für Dritte nicht zugänglich sind. Bei der Kommunikation
            per E-Mail kann die vollständige Datensicherheit von uns nicht gewährleistet werden, sodass wir Ihnen
            bei Informationen mit hohem Geheimhaltungsbedürfnis den Postweg empfehlen.</p>
    </div>
</template>