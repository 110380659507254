<template>
  <router-link :to="{ name: 'Home'}">
    <img alt="Vue logo" src="./assets/LogoDS-Values.png">
  </router-link>
  <div id="nav">
    <router-link :to="{ name: 'Impressum' }">Impressum</router-link> |
    <router-link :to="{ name: 'Privacy' }">Datenschutz</router-link>
  </div>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  margin-left: 70px;
  margin-right: 70px;
}

img {
  width: auto;
  height: auto;
}
@media only screen and (max-width: 900px) { /* Screen which has a maximum width of 900px */

  img {
    width: 100%;
  } 
  #app{
    margin-left: 25px;
    margin-right: 25px;
  }
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #2c5385;
}
</style>
