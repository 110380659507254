<template>
    <div>
        <h2>DS Values GmbH</h2>
        <p>Im Sand 44</p>
        <p>66802 Überherrn</p>
        <p>Telefon: 06836 / 800525</p>
        <p>Fax: 06836 / 5578</p>
        <p>E-Mail: <a href="mailto:info@ds-values.de">info@ds-values.de</a></p>
        <p>Internet: <a href="https://www.ds-values.de">www.ds-values.de</a></p>
        <br/>
        <p>Büro Rehlingen-Siersburg</p>
        <p>Telefon: 06833 / 173 932</p>
    </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    p{
        margin: 0px;
    }
</style>